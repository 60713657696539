import React, {Fragment, useMemo} from 'react';
import {graphql} from 'gatsby';
import Meta from '@components/Meta';
import CanonicalUrl from '@components/CanonicalUrl';
import Hero from '@components/Hero';
import {ResponsiveContainer, FontScale} from '@components/layout';
import {HeroSectionCallout} from '@components/SectionCallout';
import {
  CrosslinkSection,
  LatestGridSection,
  LatestSubNavSection,
  OneImageWithContentSection,
} from '@components/sections';
import getDataFromBlockTypes from '@util/getDataFromBlockTypes';
import {ThemeProvider, Colors, styled} from '@styles';
import {Block} from '@util/getDataFromBlockTypes';
import {useQueryParam, NumberParam} from 'use-query-params';
import queryString from 'query-string';
import useLocation from '@components/Subnavigation/useLocation';

interface Data {
  cms: CMS;
}
interface CMS {
  page: Page;
}

interface Page {
  searchDescription: string;
  seoTitle: string;
  hero: Block[];
  body: Block[];
}

const StyledCrosslinkSection = styled(CrosslinkSection).withConfig({
  componentId: 'latestCrossLink'
})`
  background: ${Colors.FogLight};
`;

export default function Latest({data, ...props}: {data: Data}): JSX.Element {
  const {page} = data.cms;
  const {hero, body, seoTitle, searchDescription, canonicalUrl} = page;
  const {header: heroHeader, pagelink} = getDataFromBlockTypes(hero);
  const externalLinks = hero.filter(item => item.blockType == 'link_block');
  // const [crossLinkBlock] = body;
  const {crossLinkBlock, ctaWithLinks} = getDataFromBlockTypes(
    body,
  );
  const {pageContext} = props;
  const {
    location: {search},
  } = useLocation();
  const parsedParams = useMemo(() => queryString.parse(search), [search]);
  const [currentPage] = useQueryParam('page', NumberParam, parsedParams);
  const shouldShowHeader = !currentPage || currentPage === 1;
  
  return (
    <Fragment>
      <Meta title={seoTitle} description={searchDescription} />
      {canonicalUrl && <CanonicalUrl canonicalUrl={canonicalUrl} />}
      <ThemeProvider
        theme={{
          fg: Colors.White,
          bg: Colors.Transparent,
          minPadding: '2em',
          maxPadding: '4em',
          maxWidth: '100%',
        }}
      >
        <ResponsiveContainer as={FontScale}>
          <ThemeProvider
            theme={{
              maxWidth: '600px',
            }}
          >
            <Hero heroSectionType={'short'}>
              <HeroSectionCallout heroProps={[heroHeader, pagelink, externalLinks]} />
            </Hero>
          </ThemeProvider>
          <LatestSubNavSection />
          <ThemeProvider
            theme={{
              fg: Colors.Charcoal,
            }}
          >
            { ctaWithLinks && shouldShowHeader && <OneImageWithContentSection {...ctaWithLinks} /> }
          </ThemeProvider>
          <LatestGridSection {...pageContext} />
          { crossLinkBlock && <StyledCrosslinkSection {...crossLinkBlock} /> }
        </ResponsiveContainer>
      </ThemeProvider>
    </Fragment>
  );
}

export const query = graphql`
  fragment LatestFragment on CMS_LatestIndexPage {
    seoTitle
    searchDescription
    canonicalUrl
    hero {
      __typename
      ...headerBlockFragment
      ...pagelinkBlockFragment
      ...externalLinkBlockFragment
      ...heroCmsImageFragment
    }
    body {
      ...crosslinkBlockFragment
      ...sectionBodyImageFragment
    }
  }
  query Latest {
    cms {
      page(url: "/home/newsroom/") {
        ...LatestFragment
      }
    }
  }
`;
